import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PaintingPreview from '../components/painting-preview'
import bannerImg from '../images/pradeep_mhapsekar.jpeg'

class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <Layout isHome={true}>
        <div className="site-intro grid">
          <div className="site-intro__img">
            <img src={bannerImg} alt="" />
          </div>
          <div className="site-intro__text">
            <h1>Pradeep Mhapsekar</h1>
            <h2>
              is mumbai based renowned cartoonist.He is known for this witty
              political cartoons.
              <br /> His work encompasses colours, lines, figures and words.
            </h2>
          </div>
        </div>

        <div className="painting-preview-wrapper">
          <div className="painting-preview-inner-wrapper">
            {data.allContentfulPainting.edges.map(edge => {
              return (
                <PaintingPreview key={edge.node.id} paintingData={edge.node} />
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query pageQuery {
    allContentfulPainting(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          name
          slug
          isSold
          images {
            title
            fluid(maxWidth: 768) {
              aspectRatio
              src
              srcSet
              sizes
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`
